.headingS {
  margin-left: 150px;
  margin-top: 150px;
  margin-bottom: 25px;
  font-size: 3.7vw;
}

.solutionsS {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px;
}

.imageContainerS {
  position: relative;
  margin: 45px;
}

.imgS {
  height: 38vh;
  width: 22vw;
  object-fit: cover;
}
.imgS:hover {
  opacity: 0.5;
  cursor: pointer;
}

.textS {
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 14pt;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  color: whitesmoke;
  padding: 15px;
  text-align: center;
  box-sizing: border-box;
}

.buttonContainerS {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.buttonS {
  color: white;
  background-color: #408697;
  border: 0px;
  width: 350px;
  height: 90px;
  border-radius: 27px;
  font-size: 20pt;
  cursor: pointer;
}

.button-hoverS {
  background-color: white;
  color: #346c7a;
  border: 2px solid #408697;
  transition: background-color 0.5s ease-out, color 0.4s ease-out,
    border 0.2s ease; /* Correct the transition property */
}

@media screen and (max-width: 1650px) {
  .headingS {
    font-size: 45pt;
  }
}
@media screen and (max-width: 1350px) {
  .imgS {
    height: 32vh;
  }
  .imageContainerS {
    margin: 30px;
  }
}

@media screen and (max-width: 1200px) {
  .imgS {
    height: 275px;
    width: 275px;
  }
  .buttonS {
    width: 300px;
    height: 80px;
    border-radius: 20px;
    font-size: 16pt;
  }
  .headingS {
    font-size: 40pt;
    margin-left: 100px;
    margin-top: 100px;
  }
}
@media screen and (max-width: 1075px) {
  .imageContainerS {
    margin: 10px;
  }
}
@media screen and (max-width: 1000px) {
  .headingS {
    margin-left: 50px;
    margin-left: 50px;
    margin-top: 100px;
  }
  .buttonS {
    width: 250px;
    height: 65px;
    border-radius: 17px;
    font-size: 16pt;
  }
}
@media screen and (max-width: 950px) {
  .imgS {
    height: 27vw;
    width: 27vw;
  }
}
@media screen and (max-width: 800px) {
  .imgS {
    height: 35vw;
    width: 75vw;
  }
  .imageContainerS {
    margin-bottom: 20px;
  }
  .headingS {
    font-size: 35pt;
    margin-top: 75px;
    text-align: center;
    margin-left: 0px;
  }
}
@media screen and (max-width: 750px) {
  .buttonS {
    width: 250px;
    height: 60px;
    border-radius: 15px;
    font-size: 14pt;
  }
  .headingS {
    font-size: 40pt;
    margin-top: 75px;
    text-align: center;
  }
}
@media screen and (max-width: 600px) {
  .headingS {
    margin-left: 20px;
    margin-right: 20px;
    font-size: 35pt;
  }

  .imgS {
    height: 45vw;
    width: 75vw;
  }
  .headingS {
    font-size: 32pt;
  }

}
@media screen and (max-width: 450px) {
  .imgS {
    height: 225px;
    width: 350px;
  }

}
@media screen and (max-width: 400px) {
  .imgS {
    width: 300px;
  }
}
@media screen and (max-width: 350px) {
  .imgS {
    width: 250px;
  }
}
@media screen and (max-width: 300px) {
  .imgS {
    width: 225px;
  }
}
