.titleR {
  font-size: 3.7vw;
  margin-left: 150px;
  margin-top: 75px;
}

.statsR {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 100px;
}

.counterR {
  flex-direction: column;
  text-align: center;
  padding-left: 30px ;
  padding-right: 30px;
  
}

.countR {
  font-size: 5.7vw;
  font-weight: 600;
  color: white;
}

.descriptionR {
  font-size: 2.7vw;
  padding-left: 25px;
  padding-right: 25px;
  width:20vw;

}
@media screen and (max-width: 1650px){
  .countR { 
    font-size: 62pt;
  }
  .titleR {
    font-size: 45pt;
    margin-left: 100px;
  }
  .descriptionR {
    font-size: 32pt;
    padding-left: 15px;
    padding-right: 15px;
    width: 300px;
  }
}

@media screen and (max-width: 1200px) {
  
  .countR { 
    font-size: 55pt;
  }
 
  .counterR {
    flex-direction: column;
    text-align: center;
    padding: 10px;
    padding-left: 20px ;
    padding-right: 20px;
  }
  .titleR {
    font-size: 40pt;
  }
  .descriptionR {
    font-size: 27pt;
    padding-left: 0px;
    padding-right: 0px;
    width: 240px;
  }
  
  
}
@media screen and (max-width: 1100px) {
  .statsR {
    margin-left: 50px;
    margin-right: 50px;
  }
}
@media screen and (max-width: 1000px){
  .titleR {
    margin-left: 50px;
    margin-right: 50px;
  }
  .descriptionR {
    font-size: 25pt;
    width: 220px;
  }
}


@media screen and (max-width: 900px){
    .statsR {
        flex-direction: column;
        align-items: center;
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 25px;
        margin-bottom: 100px;
      }
      .descriptionR {
       padding-bottom: 45px;
       width: 90vw;
      }
     .titleR {
        text-align: center;
      }
}
@media screen and (max-width: 800px) {
  .titleR {
    font-size: 35pt;
  }
  .countR { 
    font-size: 47pt;
  }
  .descriptionR {
    font-size: 25pt;
  }
}
@media screen and (max-width: 600px){
      .countR {
        font-size: 45pt;
      }
      .titleR {
        font-size: 32pt;
        margin-left: 20px;
        margin-right: 20px;
      }
      .descriptionR {
        font-size: 22pt;
        width: 85vw;
      }
}


