.contentID {
  padding-top: 100px;
  padding-bottom: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imgID {
  width: 40vw;
  max-height: 800px;
  height: 70vh;
  object-fit: cover;
  margin: 75px 25px 75px 50px;
}

.textID {
  margin-left: 25px;
  margin-right: 50px;
  color: black;
}

.textID p {
  font-size: 1.4vw;
  padding-bottom: 50px;
}

.textID .headerID {
  font-size: 3.7vw;
  padding-bottom: 50px;
}
.buttonContainerID{
  display:flex;
  justify-content: center;
}
.buttonID {
  color: white;
  background-color: #408697;
  margin-top: 40px;
  margin-bottom: 40px;
  border: 0px;
  width: 350px;
  height: 90px;
  border-radius: 27px;
  font-size: 20pt;
  cursor: pointer;
}

.button-hoverID {
  text-decoration: none;
  background-color: white;
  color: #346c7a;
  border: 2px solid #408697;
  transition: background-color 0.5s ease-out, color 0.4s ease-out, border 0.2s ease;
}

@media screen and (max-width: 1650px) {
  .textID p {
    font-size: 16pt;
  }
  
  .textID .headerID {
    font-size: 45pt;
  }
  

}

@media screen and (max-width: 1500px) {
  .imgID {
    width: 42vw; 
    margin: 100px 20px 100px 20px;
  }
  .textID {
    margin-right: 30px;
  }
}
@media screen and (max-width: 1450px) {
  .imgID {
    width: 37vw; 
    height: 67vh;
  }
}
@media screen and (max-width: 1450px) {
  .imgID {
    margin: 100px 10px 100px 10px;
  }
  .textID {
    margin-left: 14px;
    margin-right: 22px;
  }
}

@media screen and (max-width: 1200px) {
  
  .buttonID {
    width: 300px;
    height: 80px;
    border-radius: 20px;
    font-size: 16pt;
    margin-top: 10px;
  }
  .textID p {
    font-size: 16pt;
  }
  
  .textID .headerID {
    font-size: 40pt;
  }
}

@media screen and (max-width: 1000px) {
  .contentID {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    text-align: center;
    padding-top: 100px;
  }

  .textID {
    
    margin: 75px 50px 0px 50px;
  }

  .textID p {
    font-size: 16pt;
    margin-top: 0;
  }

  .textID .headerID {
    font-size: 45pt;
    margin-bottom: 0px;
    padding-bottom: 20px;
  }

  .imgID {
    width: 100%;
    height: 50vh;
    padding: 0px 50px 0px 50px;
    margin: 0px;
  }

  .buttonID {
    width: 255px;
    height: 65px;
    border-radius: 17px;
    font-size: 16pt;
    margin-top: 20px;
    order: 1; 
  }
}


@media screen and (max-width: 800px) {
  .textID p {
    font-size: 14pt;
  }

  .textID .headerID {
    font-size: 35pt;
  }

}

@media screen and (max-width: 750px) {
  .buttonID {
    width: 250px;
    height: 60px;
    border-radius: 15px;
    font-size: 14pt;
    cursor: pointer;
  }
  .imgID {
    width: 100%;
    height: 50vh;
    padding: 0px 20px 0px 20px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .textID a{
    display: flex;
    justify-content: center;
    text-decoration: none;
  }
  .textID p {
    font-size: 14pt;
    text-align: center;
  }

  .textID .headerID {
    font-size: 40pt;
    text-align: center;
  }
}

@media screen and (max-width: 600px) {
  .textID {
    margin: 75px 20px 0px 20px;
  }

  .textID .headerID {
    font-size: 35pt;
  }
}

@media screen and (max-width: 500px) {
  .buttonID {
    margin-bottom: 40px;
  }
}

body {
  overflow-x: hidden; 
}
