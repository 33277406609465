.noCareersContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 100px 100px;
  background-color: whitesmoke;
}

.noCareersImage {
  width: 50vw;
  height: auto;
  margin-bottom: 40px;
  
}

.contentNC {
  text-align: center;
}

.textNC {
  font-size: 1.7vw;
  margin-left: 50px;
  margin-right: 50px;
  color: black;
}

.buttonNC {
  color: white;
  margin-top: 50px;
  background-color: #408697;
  border: none;
  width: 375px;
  height: 70px;
  border-radius: 27px;
  font-size: 20pt;
  cursor: pointer;
}

.button-hoverNC {
  background-color: white;
  color: #346c7a;
  border: 2px solid #408697;
  transition: background-color 0.5s, color 0.4s, border 0.2s;
}
@media screen and (max-width: 1650px){
  .textNC {
    font-size: 20pt;
  }
}
/* Responsive Styles */
@media screen and (max-width: 1200px) {
  .textNC {
    font-size: 18pt;
    margin-left: 25px;
  margin-right: 25px;
  }
  .buttonNC {
    width: 300px;
    height: 70px;
    font-size: 18pt;
    border-radius: 22px;
  }
  .noCareersImage {
    width: 80vw;  
  }
}

@media screen and (max-width: 800px) {
  .buttonNC {
    width: 250px;
    height: 65px;
    font-size: 16pt;
    border-radius: 20px;
  }
  .noCareersImage {
    width: 90vw;  
  }
  .noCareersContainer {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media screen and (max-width: 600px) {
  .noCareersContainer {
    padding-left: 25px;
    padding-right: 25px;
  }
  .textNC {
    font-size: 16pt;
    margin-left: 10px;
    margin-right: 10px;
  }
  .buttonNC {
    width: 200px;
    height: 50px;
    font-size: 12pt;
    border-radius: 17px;
  }
}
