
.careersCL {
  margin-left: 100px;
  margin-right:  100px;
}

.postingsCL {
  margin: 50px;
}
.headerCL {
  font-size: 3.7vw;
}

.careerTitleCL {
  font-size: 2.3vw;
  font-weight: bold;
}
.textCL {
  font-size: 1.4vw;
}
.applyButtonCL {
  border: 2px solid #408697;
  border-radius: 10px;
  background: #408697;
  color: white;
  padding: 5px;
  width: 75px;
  cursor: pointer;
  font-size: 16pt;
}

.applyButtonCL:hover {
  background-color: white;
  color: #346c7a;
  border: 2px solid #408697;
  transition: background-color 0.5s ease-out, color 0.4s ease-out; 
}
@media screen and (max-width: 1650px) {
  .headerCL {
    font-size: 45pt;
  }
  .careerTitleCL {
    font-size: 27pt;
  }
  .textCL {
    font-size: 16pt;
  }
}
@media screen and (max-width: 1200px) {
  .headerCL {
    font-size: 40pt;
  }
  .careerTitleCL {
    font-size: 25pt;
  }
  .textCL {
    font-size: 16pt;
  }
}
@media screen and (max-width: 1000px) {
  .careersCL {
    margin-left: 50px;
    margin-right: 50px;
  }
  .applyButtonCL {
    padding: 5px;
    width: 70px;
    font-size: 14pt;
  }
 
}
@media screen and (max-width: 800px) {
  .headerCL {
    font-size: 35pt;
  }
  .careerTitleCL {
    font-size: 20pt;
  }
  .textCL {
    font-size: 14pt;
  }
  .postingsCL {
    margin: 30px 0px 50px 0px;
  }
}
@media screen and (max-width: 600px) {
  .headerCL {
    font-size: 32pt;
  }
  .careerTitleCL {
    font-size: 18pt;
  }
  .careersCL {
    margin-left: 20px;
    margin-right:  20px;
  }
}
