.text {
  display: flex;
  flex-direction: column;
  margin-left: 150px;
  margin-right: 150px;
}

.text p {
  font-size: 1.4vw;
}
.text .header {
  font-size: 3.7vw;
  margin-top: 100px;
  margin-bottom: 30px;
}
.text .sub-header {
  font-size: 2.2vw;
}
.buttonTextCP{
  display: flex;
  justify-content: center;
}
.buttonCP {
  color: white;
  margin-left: 100px;
  margin-top: 100px;
  margin-bottom: 175px;
  background-color: #408697;
  border: 0px;
  width: 350px;
  height: 90px;
  border-radius: 27px;
  font-size: 20pt;
  cursor: pointer;
}

.button-hover {
  background-color: white;
  color: #346c7a;
  border: 2px solid #408697;
  transition: background-color 0.5s ease-out, color 0.4s ease-out,
    border 0.2s ease; 
}
@media screen and (max-width: 1650px) {
  .text p {
    font-size: 16pt;
  }
  .text .header {
    font-size: 45pt;
  }
  .text .sub-header {
    font-size: 27pt;
  }
}
@media screen and (max-width: 1200px) {
  .buttonCP {
    width: 350px;
    height: 80px;
    border-radius: 20px;
    font-size: 18pt;
  }
  .text p {
    font-size: 16pt;
  }
  .text .header {
    font-size: 40pt;
  }
  .text .sub-header {
    font-size: 25pt;
  }
  .text {
    margin-right: 100px;
    margin-left: 100px;
  }
}

@media screen and (max-width: 1000px) {
  
  .text {
    margin-right: 50px;
    margin-left: 50px;
  }
  .buttonCP {
    width: 250px;
    height: 65px;
    border-radius: 17px;
    font-size: 16pt;
    margin-left: 50px;
  }
}
@media screen and (max-width: 800px) {
  .text p {
    font-size: 14pt;
  }
  .text .header {
    font-size: 35pt;
  }
  .text .sub-header {
    font-size: 20pt;
  }
}
@media screen and (max-width: 750px) {
  .buttonCP {
    width: 250px;
    height: 60px;
    border-radius: 15px;
    font-size: 14pt;
    border-radius: 19px;
    margin-left: 0px;
  }
  .buttonTextCP a{
    display: flex;
    justify-content: center;
    text-decoration: none;
  }
  .text{
    text-align: center;
  }
  .text .header {
    margin-top: 50px;
  }

}
@media screen and (max-width: 600px) {
  .text .header {
    font-size: 32pt;
  }
  .text .sub-header {
    font-size: 18pt;
  }
  .text {
    margin-right: 20px;
    margin-left: 20px;
  }
  .buttonCP {
    margin-right: 20px;
    margin-left: 20px;
  }
}
