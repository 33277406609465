.titleImageOP {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  min-height: 275px;
  position: relative;
  z-index: -1;
  overflow: hidden;
  margin-left: 0px;
}

.titleImageOPImg {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
}

.contentOP {
  display: flex;
  flex-direction: column;
  background-color: #f6f6f6;
}

.navBarOP {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 50px;
  padding-bottom: 50px;
  margin-left: 100px;
  margin-right: 100px;
}

.navBarTextOP {
  font-size: 1.4vw;
  margin-bottom: 0px;
}

@media screen and (max-width: 1650px) {
  .navBarTextOP {
    font-size: 16pt;
  }
}

@media screen and (max-width: 1000px) {
  .navBarOP {
    margin-left: 50px;
    margin-right: 50px;
  }
}
@media screen and (max-width: 800px){
  .navBarTextOP {
    font-size: 14pt;
  }
}
@media screen and (max-width: 600px){
  .navBarOP {
    margin-left: 20px;
    margin-right: 20px;
  }
}
