body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f6f6f6;
  overflow-x: hidden;
}

.layoutSR {
  display: flex;
  flex-direction: row;
}

.contentSR {
  flex-direction: column;
  align-items: center;
  margin-top: 200px;
  margin-left: 75px;
  margin-right: 50px;
  margin-bottom: 150px;
}

.headerSR {
  font-size: 3.7vw;
  font-weight: 550;
}

.textSR {
  font-size: 1.4vw;
}

.formSR {
  margin: 200px 75px 0px 50px;
  font-size: 16pt;
}

.inputHeadingSR {
  margin-bottom: 0px;
  font-size: 22px;
  font-weight: 500;
}

.labelSR {
  width: 20vw;
  padding: 5px;
  margin-bottom: 15px;
  font-size: 16pt;
}

.longlabelSR {
  width: 40vw;
  padding: 5px;
  margin-bottom: 15px;
  font-size: 16pt;
}

.resumeButton {
  width: 20vw;
  margin-bottom: 40px;
  border: none;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
}

.buttonSR {
  color: white;
  background-color: #408697;
  border: 0;
  border-radius: 7px;
  width: 100px;
  height: 40px;
  font-size: 16pt;
  cursor: pointer;
}

.buttonSR:hover {
  background-color: white;
  color: #346c7a;
  border: 2px solid #408697;
  transition: background-color 0.5s ease-out, color 0.4s ease-out, border 0.2s ease;
}

.input-fieldSR,
.input-field-largeSR {
  width: 100%;
  padding: 10px;
  font-size: 14pt;
  box-sizing: border-box;
  border: 1px solid black;
}

input[type="file"] {
  font-size: 14pt;
}

select {
  width: 100%;
  padding: 10px;
  font-size: 14pt;
  box-sizing: border-box;
}

.text-large {
  font-size: 14pt;
}

.custom-alert-overlaySR {
  position: fixed;
  font-size: 18pt;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.custom-alertSR {
  background: white;
  text-align: center;
  width:37vw;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.custom-alertSR button {
  background-color: #408697;
  border-radius: 10px;
  border: 2px solid #408697;
  font-size: 16pt;
  color: #fff;
  margin-top: 10px;
  padding: 5px 10px;
}

.custom-alertSR button:hover {
  background-color: white;
  color: #346c7a;
  border: 2px solid #408697;
  transition: background-color 0.5s ease-out, color 0.4s ease-out, border 0.2s ease;
}

.resumeButton input {
  width: 80vw;
  text-wrap: wrap;
}

.phone-format-list {
  max-height: 150px;
  overflow-y: auto;
  padding: 0;
  margin: 0;
  list-style-position: inside;
}

.phone-format-list li {
  font-size: 14pt;
}
textarea{
  border: 1px solid black;
}

@media screen and (max-width: 1650px) {
  .headerSR {
    font-size: 45pt;
  }

  .textSR {
    font-size: 16pt;
  }
  .custom-alertSR {
    width:600px;
  }
}

@media screen and (max-width: 1200px) {
  .headerSR {
    font-size: 40pt;
  }

  .textSR {
    font-size: 16pt;
  }

  .contentSR {
    margin-left: 50px;
  }

  .formSR {
    margin-left: 50px;
    margin-right:50px;
  }
  .custom-alertSR button {
    font-size: 14pt;
  }
}

@media screen and (max-width: 1000px) {
  .layoutSR {
    flex-direction: column;
    margin-left: 50px;
    margin-right: 50px;
    padding-top: 175px;
    padding-bottom: 150px;
  }
  .textSR {
    font-size: 14pt;
  }

  .contentSR {
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 50px;
  }
  .formSR {
    margin-top: 0px;
    justify-content: center;
  }

  .labelSR {
    width: 100%;
    padding: 5px;
    margin-bottom: 15px;
    font-size: 14pt;
  }

  .longlabelSR {
    width: 100%;
    padding: 5px;
    margin-bottom: 15px;
    font-size: 14pt;
  }

  .inputHeadingSR {
    font-size: 20px;
  }

}

@media screen and (max-width: 800px) {
  .custom-alert-overlaySR {
    font-size: 16pt;
  }

  .custom-alertSR {
    width: 350px;
    padding: 20px;
  }

  .custom-alertSR button {
    font-size: 14pt;
  }

  .buttonSR {
    font-size: 14pt;
  }
  .headerSR {
    font-size: 35pt;
  }
}

@media screen and (max-width: 750px) {
  .layoutSR {
    margin-left: 0px;
    margin-right: 0px;
    padding-top: 125px;
    padding-bottom: 150px;
  }
  .contentSR {
    margin-left: 50px;
    margin-right: 50px;
  }
}

@media screen and (max-width: 600px) {
  .contentSR {
    margin-left: 20px;
    margin-right: 20px;
  }
  .formSR {
    margin-left: 20px;
    margin-right:20px;
  }
  .headerSR {
    font-size: 32pt;
  }

  .custom-alert-overlaySR {
    font-size: 14pt;
  }

  .custom-alertSR {
    width: 300px;
    padding: 20px;
  }

  .custom-alertSR button {
    font-size: 12pt;
  }
}
@media screen and (max-width: 500px) {
  .custom-alert-overlaySR {
    font-size: 14pt;
  }
  
  .custom-alertSR {
    width:300px;
    padding: 20px;

  }
  .custom-alertSR button {
    font-size: 14pt;
  }
}
@media screen and (max-width: 350px) {
  .custom-alertSR {
    width: 250px;
    padding: 10px;
  }
}
