.background{
    background-image: linear-gradient(0,  #222222,#303030);
}

.items{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 40px;
    padding-bottom: 30px;
    
}
#item1{
    text-align: center;
    margin-left: 10%;
    color: white;
    padding-right : 5px;
    width: 350px;
}
#item1 h4{
    margin-bottom: 40px;
    font-size: 18pt;
    font-weight: 600;
    
} 
#item1 p{
    margin-top: 15px ;
    margin-bottom: 15px;
    font-size: 14pt;
}
#item2{
    text-align: center;
    color: white; 
    width: 350px;
}
#item2 h4{
    margin-bottom: 40px;
    font-size: 18pt;
    font-weight: 600;
    
} 
#item2 p{
    font-size: 14pt;
} 
#item2 img{
    margin-top: 20px;
    height: 65px;
}
#item3{
    text-align: center;
    margin-right: 10%;
    color: white;
    padding-left : 5px;
    width: 350px;
}
#item3 h4{
    font-size: 18pt;
    font-weight: 600;
    margin-bottom: 40px; 
} 
#item3 h5{
    margin-bottom: 25px;
    text-align: left;
    font-size: 14pt;
    
} 
#item3 ul{
    font-size: 14pt;
    text-align: left;
    padding-left: 15px;
} 
#item3 li{
   padding-top: 5px;
} 
#LinkedIn{
    height: 100px;
}
@media screen and (max-width:1300px){
    #item1{
        width: 325px;
        margin-left: 50px;
    }    
    #item1 p{
        font-size: 14pt;
    }
    #item1 h4{
        font-size: 18pt;    
    } 
    #item2{
        width: 200px;
    }
    #item2 h4{
        font-size: 18pt;    
    } 
    #item2 img{
        margin-top: 25px;
        height: 60px;
    }
    #item2 p{
        font-size: 14pt;
    }
    #item3{
        width: 325px;
        margin-right: 50px;
    }
    #item3 h4{
        font-size: 18pt;    
    } 
}
@media screen and (max-width:1200px){
    #item3 h5{
        font-size: 12pt;
        
    } 
    #item3 ul{
        font-size: 12pt;
    } 
    #item2 p{
        font-size: 12pt;
    } 
    #item1 p{
        font-size: 12pt;
    }
}
@media screen and (max-width:900px){
    #item1{
        width: 250px;
        font-size: 14pt;
        margin-left: 5%;
    }
    #item2{
        width: 250px;
        font-size: 14pt;
    }
    #item3{
        width: 250px;
        font-size: 14pt;
        margin-right: 5%;
    }
    #Accred1{
        height: 45px;
        margin:20px 0 10px 0;
    }
    #Accred2{
        height: 55px;
        margin:10px 0 10px 0;
    }
    #Accred3{
        height: 65px;
        margin:10px 0 10px 0;
    }
    #Accred4{
        height: 45px;
        margin:10px 0 0px 0;
    }
    #item2 img{
        margin-top: 20px;
        height: 60px;
    }
}
@media screen and (max-width:750px){
    .items{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    #item1{
        margin-left: 0%;
        margin-bottom: 40px;
    }
    #item1 p{
        margin-top: 0px ;
    margin-bottom: 0px;
    }
    #item2{
        margin-bottom: 40px;
    }
    #item3{
        align-items: center;
        margin-right: 0%;
        width: 80vw;
    }
    h4{
        text-decoration: underline;
    }
}
@media screen and (max-width:500px){
    #item3 h4{
        padding-top: 25px;
    } 
    #item2 h4{
        padding-top: 25px;
    }
    #item1{
        margin-bottom: 10px;
        margin-left: 0%;
    }
    #item2{
        margin-bottom: 10px;
        margin-left: 0%;
    }
    #item3{
        margin-right: 0%;
    }
    
}