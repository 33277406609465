
.listSD {
    padding: 100px;
    min-height: 85vh;
    padding-top: 175px;
}
.backToSolutions {
    position: fixed;
    top: 100px;
    left: 10px;
}

.solution-button {
    padding: 2px 15px;
    color: white;
    background-color: #222222;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.solution-button p {
    padding: 0;
    font-size: 14px;

}

.solution-button p span{

    font-size: 16px;

}
.solution-button:hover {
    background-color: #408697;

}

.backToTopButton {
    position: fixed;
    top: 150px;
    left: 10px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

.backToTopButton.visible {
    opacity: 1;
    visibility: visible;
}

.backToTopButton.hidden {
    opacity: 0;
    visibility: hidden;
}

.scroll-button {
    padding: 2px 15px;
    color: white;
    background-color: #222222;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.scroll-button p {
    padding: 0;
    font-size: 14px;

}

.scroll-button:hover {
    background-color: #408697;

}

@media screen and (max-width: 1650px){
    .projects-titleSD {
        font-size:50pt;
    }
}
@media screen and (max-width: 1200px){
    .projects-titleSD {
        font-size:45pt;
    }
}
@media screen and (max-width: 1000px){
    .listSD {
        padding: 175px 50px 150px 50px;
    }
    
    .projects-titleSD {
        padding-top: 0px;
    }
}
@media screen and (max-width: 800px){
    .projects-titleSD {
        font-size: 40pt;
    }
}
@media screen and (max-width: 750px){
    .listSD {
        padding: 125px 50px 150px 50px;
    }
}
@media screen and (max-width: 600px){
    .projects-titleSD {
        font-size: 35pt;
    }
    .listSD {
        padding: 125px 20px 150px 20px;
    }

}

