.headingSH {
    margin-left: 150px;
    padding-top: 200px;
    margin-bottom: 25px;
    font-size: 3.7vw;
  }
  .descriptionSH{
    margin:25px 150px 25px 150px;
    font-size: 1.4vw;
  }
  
  .solutionsSH {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 20px;
    margin-bottom: 150px;
    margin-top: 75px;
  }
  
  .imageContainerSH {
    position: relative;
    margin: 45px;
  }
  
  .imgSH {
    height: 38vh;
    width: 24vw;
    object-fit: cover;
  }
  .imgSH:hover {
    opacity: 0.5;
    cursor: pointer;
  }
  
  .textSH {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.65);
    color: whitesmoke;
    padding: 15px;
    text-align: center;
    box-sizing: border-box;
    font-size: 15pt;
  }
  
  @media screen and (max-width: 1650px) {
    .headingSH {
      font-size: 45pt;
    }
    .descriptionSH{
      font-size: 16pt;
    }
  }
  @media screen and (max-width: 1350px) {
    .imgSH {
      height: 37vh;
    }
  }
  
  @media screen and (max-width: 1200px) {
    .imgSH {
      height: 275px;
      width: 275px;
    }
    .buttonSH {
      width: 350px;
      height: 80px;
      border-radius: 20px;
      font-size: 22pt;
    }
    .descriptionSH{
      font-size: 16pt;
    }
    .headingSH {
      font-size: 40pt;
    }
  }
  @media screen and (max-width: 1075px) {
    .imageContainerSH {
      margin: 10px;
    }
  }
  @media screen and (max-width: 1000px) {
    .headingSH {
      margin-left: 50px;
      margin-right: 50px;
      padding-top: 175px;
    }
    .descriptionSH{
      margin:25px 50px 25px 50px;
      font-size: 16pt;
    }

  }
  
  @media screen and (max-width: 950px) {
    .imgSH {
      height: 27vw;
      width: 27vw;
    }
    .textSH {
      font-size: 14pt;
    }
  }

  @media screen and (max-width: 800px) {
    .imgSH {
      height: 35vw;
      width: 75vw;
    }
    .imageContainerSH {
      margin-bottom: 20px;
    }
    .buttonSH {
      width: 300px;
      height: 65px;
      border-radius: 18px;
      font-size: 20pt;
    }
    .headingSH {
      font-size: 35pt;
    }
    .descriptionSH{
      font-size: 14pt;
    }
  } 
  @media screen and (max-width: 750px) {
    .headingSH {
      padding-top: 125px;
    }
  }
  @media screen and (max-width: 600px) {
    .headingSH {
      margin-left: 20px;
      margin-right: 20px;
      font-size: 35pt;
    }
    .descriptionSH{
      margin:25px 20px 100px 20px;
    }
  
    .imgSH {
      height: 45vw;
      width: 75vw;
    }
    .buttonSH {
      width: 225px;
      height: 50px;
      border-radius: 16px;
      font-size: 18pt;
    }
  } 
  @media screen and (max-width: 600px) {
    .headingSH {
      font-size: 32pt;
    }
  }
  @media screen and (max-width: 450px) {
    .imgSH {
      height: 225px;
      width: 350px;
    }
    .buttonSH {
      width: 200px;
      height: 50px;
      border-radius: 14px;
      font-size: 16pt;
    }
  } 
  @media screen and (max-width: 400px) {
    .imgSH {
      width: 300px;
    }
    .textSH {
      font-size: 12pt;
    }
  } 
  @media screen and (max-width: 350px) {
    .imgSH {
      width: 250px;
    }
    .buttonSH {
      width: 150px;
      height: 50px;
      border-radius: 12px;
      font-size: 14pt;
    }
  } 
  @media screen and (max-width: 300px) {
    .imgSH {
      width: 225px;
    }
  } 