
.backgroundImageHM {
  position: fixed;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index: -1000;
}

.headImageHM p {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  position: relative;
  z-index: 1;
}

.mainTextHM {
  color: white;
  font-size: 4.5vw;
  margin-left: 150px;
  margin-right: 150px;
}

@media screen and (max-width: 1600px) {
  .mainTextHM {
    font-size: 50pt;
  }
}

@media screen and (max-width: 900px) {
  .mainTextHM {
    font-size: 45pt;
    margin-left: 75px;
    margin-right: 75px;
  }
}

@media screen and (max-width: 750px) {
  .mainTextHM {
    font-size: 40pt;
    margin-left: 50px;
    margin-right: 50px;
  }
}

@media screen and (max-width: 500px) {
  .mainTextHM {
    font-size: 40pt;
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media screen and (max-width: 400px) {
  .mainTextHM {
    font-size: 37pt;
  }
}

@media screen and (max-width: 300px) {
  .mainTextHM {
    font-size: 35pt;
  }
}
