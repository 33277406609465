body {
  margin: 0;
  background-color: #f6f6f6;
  overflow-x: hidden;
}


.layoutAP {
  display: flex;
  flex-direction: row;
}

.contentAP {
  flex-direction: column;
  margin-top: 200px;
  margin-left: 75px;
  margin-right: 50px;
}
.headerAP {
  font-size: 3.7vw;
  font-weight: 550;
}

.textAP {
  font-size: 1.4vw;
}
.formAP {
  margin: 200px 75px 200px 50px;
  font-size: 16pt;
}
.inputHeadingAP {
  margin-bottom: 0px;
  font-size: 22px;
  font-weight: 500;
}

.labelAP {
  width: 20vw;
  padding: 5px;
  margin-bottom: 15px;
  font-size: 16pt;
}
.longlabelAP {
  width: 40vw;
  padding: 5px;
  margin-bottom: 15px;
  font-size: 16pt;
}
.resumeButton {
  width: 20vw;
  margin-bottom: 40px;
  border: none;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
}

.buttonAP {
  color: white;
  background-color: #408697;
  border: 0;
  border-radius: 7px;
  width: 100px;
  height: 40px;
  font-size: 16pt;
  cursor: pointer;
}

.buttonAP:hover {
  background-color: white;
  color: #346c7a;
  border: 2px solid #408697;
  transition: background-color 0.5s ease-out, color 0.4s ease-out, border 0.2s ease;
}

.buttonAP:disabled {
  background-color: #408697; /* Keep the original background color */
  color: white; /* Keep text color unchanged */
  cursor: default; /* Arrow cursor */
  opacity: 0.5; /* Optional: make the button look visually disabled */
}

.buttonAP:disabled:hover {
  background-color: #408697; /* No hover effect */
  color: white;
  border: 0; 
}

.input-fieldAP,
.input-field-largeAP {
  width: 100%;
  padding: 10px;
  font-size: 14pt;
  box-sizing: border-box;
  border: 1px solid black;
}
input[type="file"] {
  font-size: 14pt;
}

select {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  font-size: 14pt;
  min-height: 40px;
  background-color: white;
  color: black;
}

.text-large {
  font-size: 14pt;
}
.custom-alert-overlayOP {
  position: fixed;
  font-size: 18pt;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.custom-alertOP {
  background: white;
  text-align: center;
  width:37vw;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.custom-alertOP button {
  background-color: #408697;
  border-radius: 10px;
  border: 2px solid #408697;
  font-size: 16pt;
  color: #fff;
  margin-top: 10px;
  padding: 5px 10px;
}
.custom-alertOP button:hover {
  background-color: white;
  color: #346c7a;
  border: 2px solid #408697;
  transition: background-color 0.5s ease-out, color 0.4s ease-out, border 0.2s ease; 
}

.resumeButton input{
  width: 80vw;
  text-wrap: wrap;
}
.phone-format-listOP {
  max-height: 150px;
  overflow-y: auto;
  padding: 0;
  margin: 0;
  list-style-position: inside;
}

.phone-format-listOP li {
  font-size: 14pt;
}

@media screen and (max-width: 1650px) {
  .headerAP {
    font-size: 45pt;
  }
  
  .textAP {
    font-size: 16pt;
  }
  .custom-alertOP {
    width:600px;
  }
}

@media screen and (max-width: 1200px) {
  .headerAP {
    font-size: 40pt;
  }
  .textAP {
    font-size: 16pt;
  }
  .contentAP {
    margin-left: 50px;
  }
  .formAP {
    margin: 200px 50px 200px 50px;
  }
  .custom-alertOP button {
    font-size: 14pt;
  }
}
@media screen and (max-width: 1000px) {
  .layoutAP {
    flex-direction: column;
    margin-left: 50px;
    margin-right: 50px;
  }
  .contentAP {
    margin-top: 175px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .formAP {
    margin-top: 50px;
   justify-content: center;
  }
  .labelAP {
    width: 100%;
    padding: 5px;
    margin-bottom: 15px;
    font-size: 14pt;
  }
  .longlabelAP {
    width: 100%;
    padding: 5px;
    margin-bottom: 15px;
    font-size: 14pt;
  }
  .inputHeadingAP {
    font-size: 20px;
  }
  
 
}
@media screen and (max-width: 800px){
  .layoutAP {
    margin-left: 30px;
    margin-right: 30px;
  }
  .custom-alert-overlayOP {
    font-size: 16pt;
  }
  
  .custom-alertOP {
    width:350px;
    padding: 20px;

  }
  .headerAP {
    font-size: 35pt;
  }
  .custom-alertOP button {
    font-size: 14pt;
  }
  .buttonAP{
    font-size: 14pt;
  }
  .textAP {
    font-size: 14pt;
  }
  
}
@media screen and (max-width: 750px) {
  .contentAP {
    margin-top: 125px;
  }
  .formAP {
    margin: 50px 0px 75px 0px;
  }
}
@media screen and (max-width: 600px){
  .headerAP {
    font-size: 32pt;
  }
  .layoutAP {
    margin-left: 20px;
    margin-right: 20px;
  }
}
@media screen and (max-width: 500px) {
  .custom-alert-overlayOP {
    font-size: 14pt;
  }
  
  .custom-alertOP {
    width:300px;
    padding: 20px;

  }
  .custom-alertOP button {
    font-size: 14pt;
  }
}
@media screen and (max-width: 350px){
  .custom-alertOP {
    width:250px;
    padding: 10px;

  }
}