.contentAU {
  padding: 200px 150px 150px 150px;
  background-color: #f6f6f6;
}
.ButtonContainerAU{
  display: flex;
  justify-content: center;
}

.buttonAU {
  color: white;
  margin-top: 50px;
  margin-bottom: 50px;
  background-color: #408697;
  order: 0px;
  width: 350px;
  height: 90px;
  border: 1px solid  #408697;
  border-radius: 27px;
  font-size: 20pt;
  cursor: pointer;
}

.button-hoverAU {
  background-color: white;
  color: #346c7a;
  border: 2px solid #408697;
  transition: background-color 0.5s ease-out, color 0.4s ease-out, border 0.2s ease; 
}

.header-textAU {
  font-size: 3.7vw;
}

.sub-header-textAU {
  font-size: 2.2vw;
}

.executive-titleAU {
  font-size: 3vw;
  font-weight: 600;
  margin-top: 75px;
  margin-bottom: 25px;
  text-align: center;
}

.paragraphAU {
  font-size: 1.4vw;
  margin-top: 10px;
}

#sloganAU {
  font-size: 1.6vw;
  margin-top: 10px;
  margin-bottom: 10px;
}

.teamAU {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.imgcontainerAU {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.imgAU {
  height: 19vw;
  width: 19vw;
  object-fit: cover;
  border-radius: 25px;
  margin: 70px 30px 10px 30px;
}

.img-detailsAU {
  display: flex;
  align-items: center;
  width: 19vw;
  padding-top: 5px;
}


.text-containerAU {
  text-align: left;
  margin-right: 5px;
  width: 13vw;
}
.text-containerAU p {
  font-size: 1.1vw;
}

.lineAU {
  width: 2px;
  background-color: #408697;
  height: 115px;
}

.linkedinAU {
  height: 80px;
}
.linkedinAU:hover{
  opacity: 60%;
  transition: opacity 0.3s ease-in-out ;
}

@media screen and (max-width: 1650px) {
  .header-textAU {
    font-size: 45pt;
  }
  .imgAU {
    height: 300px;
    width: 275px;
  }
  .executive-titleAU {
    font-size: 35pt;
  }
  .sub-header-textAU {
    font-size: 27pt;
  }
  .paragraphAU {
    font-size: 16pt;
  }
  #sloganAU {
    font-size: 20pt;
  }
  .img-detailsAU {
    width: 275px;
   
  }
  .text-containerAU p {
    font-size: 13pt;
  }

  .lineAU {
    width: 2px;
    background-color: #408697;
    height: 95px;
  }

  .linkedinAU {
    height: 70px;
  }
}
@media screen and (max-width: 1450px) {
  .imgcontainerAU {
    margin: 0px;
  }
  .text-containerAU p {
    font-size: 12pt;
  }
}
@media screen and (max-width: 1200px) {
  .header-textAU {
    font-size: 40pt;
  }
  .imgAU {
    height: 300px;
    width: 250px;
  }
  .executive-titleAU {
    font-size: 30pt;
  }
  .sub-header-textAU {
    font-size: 25pt;
  }
  .paragraphAU {
    font-size: 16pt;
  }
  #sloganAU {
    font-size: 18pt;
  }
  .img-detailsAU {
    width: 250px;
  }

  .linkedinAU {
    height:50px;
  }
  .buttonAU {
    width: 350px;
    height: 80px;
    border-radius: 20px;
    font-size: 18pt;
  }
  .text-containerAU p {
    font-size: 11pt;
  }
}

@media screen and (max-width: 1000px) {
  .contentAU {
    padding: 175px 50px 150px 50px;
  }
  .imgAU {
    height: 275px;
    width: 225px;
    margin: 70px 15px 10px 15px;
  }
  .buttonAU {
      width: 250px;
      height: 60px;
      border-radius: 15px;
      font-size: 14pt;
    }
  .img-detailsAU {
    width: 225px;
    padding-left: 10px;
  }
  

}

@media screen and (max-width: 850px) {
  .teamAU {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .imgAU {
    height: 300px;
    width: 250px;
    margin: 40px 0px 10px 0px;
  }
  .img-textAU {
    width: 250px;
  }
  .img-detailsAU {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-left: 0;
  }
  
  .lineAU {
    height: 2px;
    width: 95%;
  }
  
  .linkedinAU {
    height: 70px;
  
  }
  #sloganAU {
    margin-bottom: 0px;
  }
  
  .text-containerAU {
    width: 100%;
    text-align: center;
    margin: 10px 0;
  }
  
  .text-containerAU p {
    font-size: 14pt;
  }
}
@media screen and (max-width: 800px) {
  .header-textAU {
    font-size: 35pt;
  }
  .sub-header-textAU {
    font-size: 20pt;
  }
  .executive-titleAU {
    font-size: 35pt;
  }
  .paragraphAU {
    font-size: 14pt;
  }
  #sloganAU {
    font-size: 16pt;
  }
}
@media screen and (max-width: 750px) {
  .contentAU {
    padding: 125px 50px 150px 50px;
  }
  .buttonAU {
    width: 250px;
    height: 60px;
    border-radius: 15px;
    font-size: 14pt;
    border-radius: 19px;
    margin-left: 0px;
  }
}

@media screen and (max-width: 600px) {
  .contentAU {
    padding: 125px 20px 150px 20px;
  }
  .header-textAU {
    font-size: 32pt;
  }
  .sub-header-textAU {
    font-size: 18pt;
  }
  .executive-titleAU {
    font-size: 22pt;
  }
 
}
