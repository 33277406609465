.contentC {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  background-color: #f6f6f6;
  min-height: 80vh;
}

.textC {
  margin: 200px 50px 150px 150px;
  font-size: 25px;
}

.iframeC {
    margin: 150px 150px 0px 50px;
    width: 40%;
    height: 60%;
}

.formC {
    margin-top: 30px;
}

.labelC {
  padding: 0.5vw;
}

.input-shortC {
  width: 17vw;
  font-size: 16pt;
  border: 1px solid black;
}

.input-longC {
  width: 35vw;
  font-size: 16pt;
  border: 1px solid black;
}

.no-marginC {
  margin-bottom: 0px;
  margin-top: 0px;
  padding: 0px;
  font-size: 1.5vw;
}
.no-marginHeaderC {
  margin-bottom: 0px;
  margin-top: 0px;
  padding: 0px;
  font-size: 40px;
  font-weight: 600;
  font-size: 2.7vw;
}


.large-textC {
  margin-bottom: 0px;
  font-size: 25px;
  font-weight: 500;
}

.textareaC {
  width: 35vw;
  min-height: 175px;
  height: 20vh;
  font-size: 16pt;
  border: 1px solid black;
  resize: vertical;
}
.iframe-containerC {
    position: relative;
    width: 80%;
    padding-bottom: 40.25%; 
    height: 0;
    overflow: hidden;
    margin: 200px 150px 0px 50px;
  }
  
  .iframe-containerC iframe {
    position: absolute;
    top: 150;
    left: 0;
    width: 100%;
    min-height: 50vh;
    border: 0;
  padding-top: 20px;
  }
  .buttonC {
    color: white;
    background-color:#408697;;
    border: 0;
    border-radius: 7px;
    width: 100px;
    height: 40px;
    font-size: 16pt;
    cursor: pointer;
  }
  
  .buttonC:hover {
    background-color: white;
  color: #346c7a;
  border: 2px solid #408697;
  transition: background-color 0.5s ease-out, color 0.4s ease-out, border 0.2s ease; 
  }
  .custom-alert-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); 
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; 
  }
  
  .custom-alert {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .custom-alert button {
    background-color:#408697;
    border-radius:10px ;
    font-size: 16pt;
    color: #fff;
    margin-top: 10px;
    padding: 5px 10px;
    border: 2px solid #408697;
  }
  .custom-alert button:hover {
    background-color: white;
  color: #346c7a;
  border: 2px solid #408697;
  transition: background-color 0.5s ease-out, color 0.4s ease-out, border 0.2s ease; 
  }

  @media screen and (max-width: 1650px){
    .no-marginHeaderC {
      font-size: 35px;
    }
    .no-marginC {
      font-size: 18pt;
    }
    .iframe-containerC {
      width: 1000px;
      height: 625px;
      margin: 200px 150px 0px 50px;
      padding: 0; 
    }
    
    .iframe-containerC iframe {
      width: 100%;
      height: 80%;
      border: 0;
    }
  }
  @media screen and (max-width: 1400px){
    .iframe-containerC {
      width: 1000px;
      height: 625px;
      margin: 200px 100px 0px 50px;
      padding: 0; 
    }
    
  }
  @media screen and (max-width: 1200px){
    .textC {
      margin: 150px 50px 50px 50px;
      font-size: 20px;
    }
    .no-marginHeaderC {
      font-size: 30px;
    }
    .iframe-containerC {
      height: 640px;
      margin: 150px 50px 0px 50px;
      font-size: 20px;
    }
    .input-shortC {
      width: 35vw;
      font-size: 14pt;
    }
    
    .input-longC {
      font-size: 14pt;
    }
    .no-marginC {
      font-size: 16pt;
    }
  }
  
  @media screen and (max-width: 1000px){
    .no-marginC {
      font-size: 14pt;
    }
    .textC {
      margin: 175px 50px 150px 50px;
    }
    .iframe-containerC {
      margin: 175px 50px 0px 50px;
      width: 100vw;
      height: 600px;
    }
  }
  @media screen and (max-width: 900px) {
    .contentC {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .iframe-containerC {
      margin: 10px 50px 50px 50px;
    }
    .input-shortC {
      width: 60vw;
    }
    
    .input-longC {
      width: 60vw;
    }
    .textareaC {
      width: 60vw;
    }
    .textC {
      margin: 150px 50px 50px 50px;
    }
  }
  @media screen and (max-width: 800px) {
    .buttonC {
      font-size:14pt;
      
    }
    
  }
  @media screen and (max-width: 750px) {
    .iframe-containerC iframe {
     height: 500px;
    }
    .textC {
      margin: 125px 50px 50px 50px;
    }
  }
 
  @media screen and (max-width: 600px) {
    .iframe-containerC iframe {
      min-height: 400px;
    }
    .iframe-containerC {
      margin-bottom: 0;
      width: 100%;
    }
    .textC {
      margin-left:25px;
      margin-right:25px;
    }
    .input-shortC {
      width: 80vw;
    }
    .no-marginHeaderC {
      font-size: 25px;
    }
    .large-textC {
      font-size: 20px;
    }
    .input-longC {
      width:80vw;
    }
    .textareaC {
      width: 80vw;
    }
    
  }
  @media screen and (max-width: 450px) {
    .iframe-containerC iframe {
      height: 40vh;
      padding-bottom: 0%;
    }
  
  }
  