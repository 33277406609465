.projectSL {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.subHeadingSL{
  font-size: 3.7vw;
  
}
.textSL{
margin-bottom: 40px;
margin-top: 20px;
font-size:1.4vw ;
}
.introSL{
font-size: 2.2vw;
margin-top: 75px;
margin-bottom: 50px;
font-weight: 600;

}

.informationSL {
  margin-top: 25px;
  margin-bottom: 20px;

}

.rectangleSL {
  display: inline-block;
  width: 25vw;
  height: 2px;
  background: #408697;
}
p {
  font-size:1.4vw;
  margin: 0;
  padding: 5px 0;
}
.projectItem {
  display: flex;
  
  gap: 20px;
  margin-bottom: 75px;
}

.projectItem.left {
  flex-direction: row;
}

.projectItem.right {
  flex-direction: row-reverse;
}

.textContainer {
  flex: 1;
}

.imageContainer {
  width: 450px;
  height: 500px;
  display: flex;
  justify-content: center;
}

.projectImage {
  width: 450px;
  height: 400px;
  object-fit: cover;
}


  @media screen and (max-width: 1650px){
    .textSL{
      font-size:16pt
    }
    p {
      font-size:16pt;
    }
    .subHeadingSL{
      font-size: 45pt;
    }
    .introSL{
      font-size: 27pt;
    }
  }
  @media screen and (max-width: 1550px){
    .imageContainer {
      width: 400px;
      height: 450px;
    }
    
    .projectImage {
      width: 400px;
    }
    
  }
  @media screen and (max-width: 1200px){
    .textSL{
      font-size:16pt
    }
   
    .informationSL {
      margin-right:0px ;
      margin-top: 20px;
    }
    .subHeadingSL{
      font-size: 40pt;
    }
    .introSL{
      font-size: 25pt;
      margin-top: 50px;
      margin-bottom: 30px;
    }
    .projectItem {
      flex-direction: column;
    }
  
    .projectItem.right {
      flex-direction: column;
    }
  
    .projectItem.left {
      flex-direction: column;
    }
    .imageContainer {
      width: 100%;
      height: 500px;

    }
    .projectImage {
      width:100%;
      height: 425px;
      object-fit: cover;
    }
  }
  @media screen and (max-width: 800px){
    .textSL{
      font-size:14pt
    }
    p {
      font-size:14pt;
    }
    .introSL{
      font-size: 20pt;
      margin-top: 40px;
      margin-bottom: 25px;
    }
    .subHeadingSL{
      font-size: 35pt;
    }
   
  }
  
  @media screen and (max-width: 600px){
    .subHeadingSL{
      font-size: 32pt;
    }
    .introSL{
      font-size: 18pt;
    }
  }