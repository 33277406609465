.layoutPS {
  display: flex;
  flex-direction: column;
  padding-top: 100px;
  color: black;
  overflow-x: hidden;
  position: relative;
  background-color: #f6f6f6;
}

.titlePS {
  padding-top:0px;
  padding-bottom: 0px;
  margin-bottom:0px;
  margin-bottom: 0px;
  font-size: 3.7vw;
  margin-left: 150px;
}

.subtitlePS {
  font-size: 2.2vw;
  margin-top: 0px;
  margin-bottom: 75px;
  margin-left: 150px;
}

.carousel-image {
  width: 100%;
  height: 60vh;
  max-height: 800px;
  object-fit: cover;
  display: block;
  top: 50%;
  left: 50%;
}

.dot {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: #ccc;
  border-radius: 50%;
  margin: 0 7px 75px 7px;
  cursor: pointer;
}

.dot.active-dot {
  background-color: #000;
  width: 30px;
  border-radius: 10px;
  transition: width 0.4s ease-out;
}

.dot.inactive-dot {
  background-color: #afafaf;
}

.carousel-wrapper {
  position: relative;
  width: 70vw;
  margin: 0 auto;
}

.carousel-arrow {
  position: absolute;
  top: 41%;
  transform: translateY(-50%);
  font-size: 25pt;
  font-weight: 900;
  cursor: pointer;
}

.alice-carousel__stage-item {
  transition: opacity 4s ease-out;
}

.carousel-arrow-prev {
  left: -5%;
}

.alice-carousel__stage-item.__active {
  opacity: 1 !important;
}

.carousel-arrow-next {
  right: -5%;
}

.alice-carousel__stage-item.__inactive {
  opacity: 0 !important;
}

.image-container {
  position: relative;
}
.image-container.blur img {
  transition: 0.6s ease-in-out;
  filter: brightness(20%);
}

.buttonPS {
  position: absolute;
  top: 0px;
  left: 50%;
  text-align: center;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.65);
  color: whitesmoke;
  width: 100%;
  height: 50px;
  font-size: 1.2vw;
  cursor: pointer;
  z-index: 10;
  border: none;
}

.close-button {
  position: absolute;
  top:3%;
  right:3%;
  color: white;
  background: none;
  border: none;
  font-size: 1.4vw;
  cursor: pointer;
  z-index: 20;
  display: none;
}
.close-button:hover {
  color: #afafaf;
}
.hide-button {
  display: none;
}

.overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  width: 95%;
  max-height: 90%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
}

.image-container:hover .overlay-text {
  opacity: 1;
  visibility: visible;
  
}


.overlay-text.fade-in {
  opacity: 1;
  visibility: visible; /* Show overlay */
}

.overlay-text.fade-out {
  opacity: 0;
  visibility: hidden; /* Hide overlay */
}

.overlay-text p {
  font-size: 1.4vw;
 padding-left: 30px;
 padding-right: 30px;
}

@media screen and (max-width: 1650px) {
  .titlePS {
    font-size: 45pt;
  }
  .subtitlePS {
    font-size: 27pt;
    margin-right: 100pt;
  }
  .buttonPS {
    height: 40px;
    font-size: 14pt;
    width: 46vw;
  }
  .close-button {
    font-size: 20pt;
  }
  .overlay-text p {
    font-size: 16pt;
  }
}
  @media screen and (max-width: 1200px){
    .titlePS {
      font-size: 40pt;
      margin-left: 100px;
      margin-right: 100px;
    }
    .subtitlePS {
      font-size: 25pt;
      margin-left: 100px;
      margin-right: 100px;
    }
    .overlay-text p {
      font-size: 16pt;
    }
    .buttonPS {
      width: 57vw;
    }
    .carousel-image {
      height: 50vh;
    }
    .carousel-wrapper {
      width: 82vw;
    }
    
  }

@media screen and (max-width: 1000px) {
  .carousel-arrow {
    display: none;
  }
  .close-button {
    display:block; 
  }
  .carousel-image {
    height: 50vh;
  }
  .carousel-wrapper {
    width: 92vw;
  }
  
  .titlePS {
    margin-left: 50px;
    margin-right: 50px;
  }
  .subtitlePS {
    margin-bottom: 30pt;
    margin-left: 50px;
    margin-right: 50px;
  }
  .buttonPS {
    width: 70vw;
  }
  .close-button {
    font-size: 16pt;
  }
  .dot.active-dot {
    width: 40px;
    border-radius: 12px;
  }
  
  .dot {
    width: 15px;
    height: 15px;
    margin: 0 9px 75px 9px;
  }
}
@media screen and (max-width: 800px){
  .titlePS {
    font-size: 35pt;
  }
  .subtitlePS {
    font-size: 20pt;
  }
  .overlay-text p {
    font-size: 14pt;
  }
  .buttonPS {
    width: 80vw;
  }
  .carousel-image {
    height: 47vh;
  }
  .close-button {
    font-size: 14pt;
  }
}
@media screen and (max-width: 750px){
  .layoutPS{
    text-align: center;
  }
  .buttonPS {
    width: 92vw;
    border-radius: 0px;
    padding-left: 10px;
    padding-right: 10px;
  }

}
@media screen and (max-width: 600px){
.titlePS {
  margin-left: 20px;
  margin-right: 20px;
  font-size: 32pt;
}
.subtitlePS {
  margin-left: 20px;
  margin-right: 20px;
  font-size: 18pt;
}
.overlay-text p {
 padding-left: 5px;
 padding-right: 5px;
}
.buttonPS {
  height: 55px;
    font-size: 12pt;
}
.close-button {
  font-size: 12pt;
}
}

